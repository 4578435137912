<template>
    <div>
        <b-container>
            <b-row cols="3" align-v="center">
                <b-col>
                    Per Page: <b-select
                    style="width: 6rem"
                    :options="perPageOption"
                    v-model="perPage"
                    @change="perPageChange"
                ></b-select>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="refreshFwList">Refresh List</b-button>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <b-table
            striped
            bordered
            small
            hover
            head-variant="light"
            selectable
            @row-selected="onRowSelected"
            @row-contextmenu="rightClicked"
            select-mode="single"
            responsive="sm"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="fwItems"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
        >
            <template #cell(url)="data">
                <span v-html="data.value"></span>
            </template>
        </b-table>
        <div>
            <b-container class="border border-info">
                <b-row cols="2" align-v="center" align-h="center">
                    <b-col>
                        <b-card-text class="mt-1 mb-1">Currently Selected FW:</b-card-text>
                    </b-col>
                    <b-col>
                        <b-form-input class="text-center mt-1 mb-1" v-model="selFW" disabled></b-form-input>
                    </b-col>
                </b-row>
            </b-container>
            <p></p>
        </div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: "FwListPicWd",
    data() {
        return {
            fields: [
                {key: 'idx', label: 'Index', sortable: true, thStyle: { width: '5rem'}},
                {key: 'verStr', label: 'FW Version', sortable: true},
                {key: 'url', label: 'Link', formatter: this.urlformatter}
            ],
            perPageOption: [5, 10, 25, 50, 100],
            sortBy: 'idx',
            sortDesc: true,
            selected: [],
            selectedUser: null,
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
        }
    },
    created() {
        this.perPage = this.$store.getters.getAdminFwPerPage;
        this.$store.dispatch('getFwList', 'PicWd').then(() => {
            this.totalRows = this.$store.getters.fwListPicWd.length;
        }).catch(() => {

        });
    },
    mounted() {

    },
    methods: {
        perPageChange(value) {
            this.$store.dispatch('updateAdminFwPerPage', value);
        },
        refreshFwList() {
            this.$store.dispatch('getFwList', 'PicWd').then(() => {
                this.totalRows = this.$store.getters.fwListPicWd.length;
            });
        },
        urlformatter(value) {
            let link = "https://app.smart-mist.com" + value;
            let fileLink = value.split('/')[2];
            let htmlLink = '<a href="' + link + '">' + fileLink + '</a>'
            return htmlLink;
        },
        onRowSelected(items) {
            this.selected = items[0];
            this.$store.commit('storeSelectedFw', this.selected);
        },
        rightClicked (item) {
            this.selected = item;
            this.$store.commit('storeSelectedFw', this.selected);
        }
    },
    computed: {
        fwItems() {
            return this.$store.getters.fwListPicWd
        },
        selFW() {
            if (this.selected !== undefined)
                return this.selected.verStr
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
